import { Configuration, RedirectRequest } from "@azure/msal-browser";
import { Environment } from "../shared/models/UtilsTypes";

export const environment: Environment = {
    PRODUCTION: false,
    BASE_URL: "https://areservationapi-dev.azurewebsites.net",
    BASE_SIGNALR: "https://dev-signalr-indexic.azurewebsites.net/api",
    BASE_VIATOR: "https://areservationapi-dev.azurewebsites.net",
};
export const PRODUCTION = false;
export const BASE_URL = "https://areservationapi-dev.azurewebsites.net";
export const BASE_SIGNALR = "https://dev-signalr-indexic.azurewebsites.net/api";
export const BASE_VIATOR = "https://areservationapi-dev.azurewebsites.net";
export const scopes = ["openid"];
export const REACT_APP_STRIPE_KEY =
    "pk_test_51MHUTLJPqlMypbZJ76pPvMNxMqoEUUuvTPKtuq37bF0vXDBRrVlk9Knaf9LpohKuiUP24Uc9VsbZg93zwGTamAYm00uhR2edfl";
export const REACT_APP_STRIPE_VERSION = "2022-11-15";
export const REACT_APP_APPINSIGHTS_KEY = "c95240c6-77b9-4107-b406-718e5cf94b6d";
export const MUI_KEY =
    "5a7e04a894151677e6a7839ddebf36daTz03OTg5OSxFPTE3MzMxNzA4NTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "5f550a6a-a966-4d83-bfb9-9be41e21ed01",
        authority:
            "https://IndexicDevAuth.b2clogin.com/IndexicDevAuth.onmicrosoft.com/B2C_1_aResUserFlow",
        knownAuthorities: ["https://IndexicDevAuth.b2clogin.com/"],
        redirectUri: "https://dev.areservation.com",
        postLogoutRedirectUri: "https://www.indexic.net",
    },
    cache: {
        cacheLocation: "localStorage",
    },
};
